<template>
  <table class="not-hoverable">
    <thead>
      <th style="width: 50%;">Service Name</th>
      <th>Part Price</th>
      <th>Labour Price</th>
      <th>Total Price</th>
    </thead>
    <tbody>
      <tr v-for="service in services" :key="service.id">
        <td>{{ service.details.name }}</td>
        <td>£{{ service.prices.partPrice.toFixed(2) }}</td>
        <td>£{{ service.prices.labourPrice.toFixed(2) }}</td>
        <td>£{{ service.prices.totalPrice.toFixed(2) }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td></td>
        <td></td>
        <td class="footer-top">Subtotal</td>
        <td class="footer-top">£{{ prices.subtotal.toFixed(2) }}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td>Discount</td>
        <td>{{ prices.discount }}%</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td>Tax</td>
        <td>{{ prices.tax }}%</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td class="footer-bottom">Total Due</td>
        <td class="footer-bottom">£{{ prices.total.toFixed(2) }}</td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  props: ['services', 'prices']
}
</script>

<style scoped>
  table {
    margin-top: 30px;
  }
  table tfoot td {
    padding: 3px 10px;
    text-align: right;
    font-weight: 600;
  }
  table tfoot td.footer-top {
    padding-top: 10px;
  }
  table tfoot td.footer-bottom {
    font-size: 18px;
    font-weight: 800;
  }
</style>