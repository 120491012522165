<template>
  <div>
    <span class="label">Company:</span>
    <span class="info">Auto Master Centre LTD</span>
  </div>
  <div>
    <span class="label">Company Number:</span>
    <span class="info">11067839</span>
  </div>
  <div>
    <span class="label">Address:</span> 
    <span class="info">4-6 Broadway Mews, London N21 3PB</span>
  </div>
  <div>
    <span class="label">Phone Number:</span>
    <span class="info">020 8360 6189</span>
  </div>
  <div>
    <span class="label">Email:</span>
    <span class="info">office@automastercentre.co.uk</span>
  </div>
  <div>
    <span class="label">Bank Name:</span>
    <span class="info">HSBC</span>
  </div>
  <div>
    <span class="label">Sort Code:</span>
    <span class="info">40-05-07</span>
  </div>
  <div>
    <span class="label">Account Number:</span>
    <span class="info">61504460</span>
  </div>
</template>

<style scoped>
  span {
    line-height: 22px;
  }
  .label {
    display: inline-block;
    width: 150px;
    vertical-align: top;
    font-weight: 800;
  }
  .info {
    display: inline-block;
    width: calc(100% - 150px);
  }
</style>