<template>
  <div>
    <span class="label">Client Name:</span>
    <span class="info" :class="{ tilted: !client.name }">
      {{ client?.name || 'Unknown' }}
    </span>
  </div>
  <div>
    <span class="label">Client Address:</span> 
    <span class="info" :class="{ tilted: !client.address }">
      {{ client?.address || 'Unknown' }}
    </span>
  </div>
  <div>
    <span class="label">Client Phone:</span>
    <span class="info" :class="{ tilted: !client.tel }">
      {{ client?.tel || 'Unknown' }}
    </span>
  </div>
  <div>
    <span class="label">Client Email:</span>
    <span class="info" :class="{ tilted: !client.email }">
      {{ client?.email || 'Unknown' }}
    </span>
  </div>
  <div>
   <span class="label">Car Reg. No.:</span>
    <span class="info" :class="{ tilted: !booking?.details.regNo }">
      {{ booking?.details.regNo || 'Unknown' }}
    </span>    
  </div>
  <div>
    <span class="label">Car Make:</span>
    <span class="info" :class="{ tilted: !booking?.details.make }">
      {{ booking?.details.make || 'Unknown' }}
    </span>
  </div>
  <div>
    <span class="label">Car Mileage:</span>
    <span class="info" :class="{ tilted: !booking?.details.miles }">
      {{ booking?.details.miles || 'Unknown' }}
    </span>
  </div>
  <div>
    <span class="label">Issue Date:</span>
    <span class="info" :class="{ tilted: !details.issueDate }">
      {{ details.issueDate ? new Date(details.issueDate).toLocaleDateString() : 'Unknown' }}
    </span>
  </div>
  <div>
    <span class="label">Due Date:</span>
    <span class="info" :class="{ tilted: !details.dueDate }">
      {{ details.dueDate ? new Date(details.dueDate).toLocaleDateString() : 'Unknown' }}
    </span>
  </div>
  <div>
    <span class="label">{{ details.isEstimate ? 'Estimate' : 'Invoice'}} Number:</span>
    <span class="info" :class="{ tilted: !details.invoiceNo }">
      {{ details.invoiceNo || 'Unknown' }}
    </span>
  </div>
</template>

<script>
export default {
  props: ['details', 'client', 'booking']
}
</script>

<style scoped>
  span {
    line-height: 22px;
  }
  .label {
    display: inline-block;
    width: 150px;
    vertical-align: top;
    font-weight: 800;
  }
  .info {
    display: inline-block;
    width: calc(100% - 150px);
  }
</style>