<template>
  <div v-if="invoice" class="page-header">
    <div>
      <h3>{{ invoice?.details.isEstimate ? 'Estimate' : 'Invoice' }}</h3>
      <h6 v-if="invoice">Status: {{ invoice.details.status.charAt(0).toUpperCase() + invoice.details.status.slice(1).replace(/-/g, ' ') }}</h6>
    </div>
    <div>
      <button @click="handleDownload">Download PDF</button>
      <button @click="handleEmail">Email PDF</button>
      <button class="secondary" @click="showInvoiceModal = true">Edit {{ invoice.details.isEstimate ? 'Estimate' : 'Invoice'}}</button>
      <button class="danger" @click="handleDelete">Delete {{ invoice.details.isEstimate ? 'Estimate' : 'Invoice'}}</button>
    </div>
  </div>
  <div v-if="!isLoading && invoice" class="container">
    <div class="top-section">
      <div class="inner-container">
        <div class="page-subtitle">Issued By</div>
        <InvoiceInfo />
      </div>
      <div class="inner-container">
        <div class="page-subtitle">{{ invoice.details.isEstimate ? 'Estimate' : 'Invoice'}} Details</div>
        <InvoiceDetails :details="invoice.details" :client="invoice.client" :booking="invoice.booking" />
      </div>
    </div>
    <div class="inner-container">
      <div class="page-subtitle">{{ invoice.details.isEstimate ? 'Estimate' : 'Invoice'}} Items</div>
      <InvoiceItems :services="invoice.services" :prices="invoice.prices" />
    </div>
  </div>
  <div v-else-if="isLoading" class="container">
    <div class="loading-documents">LOADING...</div>
  </div>
  <div v-else-if="error" class="container">
    <div class="loading-documents error">{{ error }}</div>
  </div>
  <div class="loading-page" :class="{ shown: fetchLoad }"><img src="@/assets/images/spinner.svg" alt="Loading..."></div>
  <InvoiceModal v-if="showInvoiceModal" :mode="'edit'" :invoice="JSON.parse(JSON.stringify(invoice))" :services="invoice.services" @modal-close="showInvoiceModal = false" />
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useFirestore from '@/composables/useFirestore'
import useFetch from '@/composables/useFetch'
import InvoiceInfo from '@/components/Invoice/Info.vue'
import InvoiceDetails from '@/components/Invoice/Details.vue'
import InvoiceItems from '@/components/Invoice/Items.vue'
import InvoiceModal from '@/components/Invoice/Modal.vue'
import { Swal, Toast } from '@/composables/useSwal'
import download from 'downloadjs'

export default {
  props: ['id'],
  components: { InvoiceInfo, InvoiceDetails, InvoiceItems, InvoiceModal },
  setup(props) {
    const router = useRouter()
    const { document: invoice, error, isLoading, listenDocument } = useFirestore()
    const { error: fetchError, isLoading: fetchLoad, fetchData } = useFetch()
    const showInvoiceModal = ref(false)
    
    listenDocument('invoices', props.id)

    const handleDownload = async () => {
      const fileBlob = await fetchData(`/invoices/${props.id}/download`, 'get');

      if (!fetchError.value) {
        download(fileBlob, invoice.value ? `${invoice.value.details.isEstimate ? 'Estimate' : 'Invoice'} ${invoice.value.details.invoiceNo}` : `${invoice.value.details.isEstimate ? 'Estimate' : 'Invoice'}`)
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${fetchError.value}`,
        })
      }
    }

    const handleEmail = async () => {
      const clientName = invoice.value.client.name ? invoice.value.client.name.split(' ')[0] : 'there'
      
      const popup = await Swal.fire({
        title: `Email ${invoice.value.details.isEstimate ? 'Estimate' : 'Invoice'}`,
        showCancelButton: true,
        confirmButtonText: `Email ${invoice.value.details.isEstimate ? 'Estimate' : 'Invoice'}`,
        html: 
        `<h5>The text below is the email\'s body - feel free to edit it before sending. The ${invoice.value.details.isEstimate ? 'estimate' : 'invoice'} will automatically be attached as a PDF to the email.</h5><br>
        <textarea id="email-body" style="resize: none;background-color: var(--bg);border-radius: 5px;min-height: 220px;padding: 20px;">
Hi ${clientName},\n
Please find attached the ${invoice.value.details.isEstimate ? 'estimate' : 'invoice'} prepared for your booking.\n
If you have any questions, feel free to reply to this email.\n
Regards,
Team Auto Master Centre</textarea>`,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise(resolve => {
            Swal.resetValidationMessage();
            resolve(document.getElementById('email-body').value);
          });
        }
      })

      if (popup.isConfirmed) {
        await fetchData(`/invoices/${props.id}/email`, 'post', { text: popup.value })

        if (!fetchError.value) {
          Toast.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Invoice emailed successfully',
          })
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Uh-oh...',
            text: `Error: ${fetchError.value}`,
          })
        }
      }
    }

    const handleDelete = async () => {
      const popup = await Swal.fire({
        title: 'Uh-oh...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Delete Invoice`,
        html: 
        `<h4>Deleting this Invoice is irreversible and you'll lose all of its data.</h4><br>
        <h4>Do you confirm?</h4></div>`
      })

      if (popup.isConfirmed) {
        await fetchData(`/invoices/${props.id}`, 'delete')

        if (!fetchError.value) {
          Toast.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Invoice deleted successfully',
          })

          router.go(-1)
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Uh-oh...',
            text: `Error: ${fetchError.value}`,
          })
        }
      }
    }

    return { invoice, error, isLoading, fetchLoad, showInvoiceModal, handleDownload, 
      handleEmail, handleDelete }
  }
}
</script>

<style scoped>
  .top-section {
    display: flex;
    justify-content: space-between;
  }
  .top-section .inner-container {
    width: 49%;
    min-height: 320px;
  }
</style>